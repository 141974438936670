<div class="main-banner main-banner-two">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h3 style="line-height:50px">قال تعالى: "إِنَّ هذَا الْقُرْآنَ يَهْدِي لِلَّتِي هِيَ أَقْوَمُ وَيُبَشِّرُ الْمُؤْمِنِينَ الَّذِينَ يَعْمَلُونَ الصَّالِحَاتِ أَنَّ لَهُمْ أَجْرًا كَبِيرًا"</h3>
                                <p>حَدَّثَنِي أَبُو عُبَيْدَةَ ، عَنْ جَابِرِ بْنِ زَيْدٍ ، قَالَ : بَلَغَنِي عَنْ رَسُولِ اللَّهِ صَلَّى اللَّهُ عَلَيْهِ وَسَلَّمَ ، أَنَّهُ قَالَ : " عَلِّمُوا أَوْلادَكُمُ الْقُرْآنَ فَإِنَّهُ أَوَّلُ مَا يَنْبَغِي أَنْ يُتَعَلَّمَ مِنْ عِلْمِ اللَّهِ هُوَ ".</p>

                             <br />

                                <div class="count-down-timer">
                                    <p>المتبقي لآخر موعد للتسجيل</p>
                                    <div class="wrapper">
                                        <div class="description">
                                            <p>أيام</p>
                                            <p>ساعات</p>
                                            <p>دقائق</p>
                                            <p>ثواني</p>
                                        </div>
                                        <div class="times">
                                            <p #days></p>
                                            <p #hours></p>
                                            <p #minutes></p>
                                            <p #seconds></p>
                                        </div>
                                    </div>
                                    <div>
                                        <a routerLink="/register_terms" class="default-btn">
                                            <i class="bx bxs-edit"></i>سجل الآن<span></span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="banner-image-slides owl-carousel owl-theme">
                    <div class="banner-image banner-slider-bg2"></div>
                    <div class="banner-image banner-slider-bg1"></div>
                    <div class="banner-image banner-slider-bg3"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape20"><img src="assets/img/shape/shape19.png" alt="image"></div>
    <div class="shape21"><img src="assets/img/shape/shape20.png" alt="image"></div>
    <div class="shape19"><img src="assets/img/shape/shape18.png" alt="image"></div>
    <div class="shape22"><img src="assets/img/shape/shape21.png" alt="image"></div>
    <div class="shape23"><img src="assets/img/shape/shape22.svg" alt="image"></div>
    <div class="shape24"><img src="assets/img/shape/shape23.png" alt="image"></div>
    <div class="shape26"><img src="assets/img/shape/shape25.png" alt="image"></div>
</div>


<div class="features-card-section pt-100 pb-70 bg-f8fbfa">
    <div class="container">
        <div class="section-title">
            <h2>دوافع المشروع</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="single-features-card tx-center">
                    <i class='bx bx-brain'></i>
                    <h6>ملاحظة ضعف الطلاب في أحكام التجويد وتلاوة القرآن الكريم.</h6>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <div class="single-features-card tx-center">
                    <i class='bx bx-link'></i>
                    <h6>الحاجة الملحة لربط الطلاب بالقرآن الكريم.</h6>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                <div class="single-features-card tx-center">
                    <i class='bx bxs-time'></i>
                    <h6>عدم تخصيص برامج تلاوة للقرآن الكريم بطريقة ممنهجة ومستمرة.</h6>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                <div class="single-features-card tx-center">
                    <i class='bx bxs-check-circle'></i>
                    <h6>نجاح المشروع سنة بعد سنة بشهادة طلاب المشروع وأولياء أمورهم وإدارات ومعلمي المدارس.</h6>
                </div>
            </div>
        </div>
    </div>
</div>



<section class="services-area bg-right-shape ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/service2.png" alt="image">
                </div>
            </div>

            <div class="services-content it-service-content">
                <div class="content left-content">
                    <h2>أهداف المشروع:</h2>
                    <br />
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</p>-->
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> تنمية الشعور بأهمية القرآن الكريم وتلاوته وتجويده لدى الطلاب.
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>تلاوة القرآن الكريم تلاوة مجودة خالية من أخطاء التشكيل.
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>العمل على تحفيز الطلاب على تلاوة القرآن الكريم في المسجد والمدرسة.
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>خدمة المجتمع في تخريج طلبة يتقنون تلاوة وحفظ القرآن الكريم.
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>تجهيز الطلاب للمشاركة الفاعلة في المسابقات القرآنية.
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h4 style="line-height:45px">...فإن القرآن الكريم مصدر كل خير ومنبع كل هداية ولذلك فإنه يجب علي المسلمين أن تتظافر جهودهم علي نشره في أوساط البشر حتى يملأوا بهذا النور الأفق البشري فيخرجوا هذه الإنسانية الضالة الحائرة من متاهتها إلي العلم واليقين والمعرفة والنور...</h4>
            <p>سماحة الشيخ أحمد بن حمد الخليلي</p>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>

</section>
